import React from 'react';
import House from "./House";
import People from "./People";
import axios from "axios";
import {Redirect} from "react-router-dom";

function redirectTo() {
    return <Redirect to="/profile" />;
}

function Home({authenticated, isRedirected, attachJS}) {

    const [userlist, setUserist] = React.useState(null);

    const fetchUsers = () => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/api/users`)
            .then(function (res) {
                if (res.data.users){
                    setUserist(res.data.users);
                    attachJS([{ src: "/js/rebatchCards.js" }]);
                }
            })
    };

    React.useEffect(() => {
        fetchUsers();
    }, []);

    if (isRedirected) return redirectTo();
    return (
        <>
            <span className="js-widget-page d-none"></span>

            <section id="living2" className="home-section wg-featured " style={{paddingTop : "17px", paddingBottom : "15px"}}>
                <div className="container">
                    <House
                        title="2호점"
                        subtitle="2호점 소개글"
                        images={
                            [
                                'https://cdn.imweb.me/thumbnail/20191126/ce662132535f5.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/172431fd87f0a.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/07da146037519.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/8d6007011d984.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/21b18598be7df.jpg',
                                'https://cdn.imweb.me/thumbnail/20191126/1a70fa38ad467.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/25d52f5fe846c.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/8af4c854218cb.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/d69a2df42eda0.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/466d52a30a6db.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/73ec1bed4f71e.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/2a5164c566cd6.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/95cb1591f3322.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/7cfe22c103061.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/3d30a893d82e7.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/a0391aee94746.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/8287380ff86f8.jpg',
                            ]
                        }
                    />
                    <People authenticated={authenticated} userlist={userlist} residence="living2"/>
                </div>
            </section>


            <section id="living3" className="home-section wg-featured " style={{paddingTop : "17px", paddingBottom : "15px"}}>
                <div className="container">
                    <House
                        title="3호점"
                        subtitle="3호점 소개글"
                        images={
                            [
                                'https://cdn.imweb.me/thumbnail/20191106/fe4372b08571a.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/ac7a09a50c6a6.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/565234ef78491.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/e028396e3293d.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/6f3f5ba52a193.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/2dddd98be2115.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/3a37fb3fae71c.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/b7e324d3c3e51.jpg',
                                'https://cdn.imweb.me/thumbnail/20191126/6cb6d27aa8982.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/31778f6b8fa94.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/8dee715eccd83.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/45acf143a4d28.jpg',
                                'https://cdn.imweb.me/thumbnail/20191126/e002cb632bfaa.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/cd7e50d6790b6.jpg',
                            ]
                        }
                    />
                    <People authenticated={authenticated} userlist={userlist} residence="living3"/>
                </div>
            </section>

            <section id="living4" className="home-section wg-featured " style={{paddingTop : "17px", paddingBottom : "15px"}}>
                <div className="container">
                    <House
                        title="4호점"
                        subtitle="4호점 소개글"
                        images={
                            [
                                'https://cdn.imweb.me/thumbnail/20191121/9aa5f8b580fb3.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/b9304b76e9de3.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/a5506ac590712.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/dc905056a65ba.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/6b67bc7e4dce1.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/78072afd1038c.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/780981906635b.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/d93cc09d63636.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/a50caa39a820b.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/f1422d6d4fbc2.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/d9bbfa23590d6.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/a086c47d5a1cc.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/6dfd6a68ec1cd.jpg',
                                'https://cdn.imweb.me/thumbnail/20191106/4bda301b1a318.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/9ccaea042a552.jpg',
                            ]
                        }
                    />
                    <People authenticated={authenticated} userlist={userlist} residence="living4"/>
                </div>
            </section>

            <section id="living5" className="home-section wg-featured " style={{paddingTop : "17px", paddingBottom : "15px"}}>
                <div className="container">
                    <House
                        title="5호점"
                        subtitle="5호점 소개글"
                        images={
                            [
                            ]
                        }
                    />
                    <People authenticated={authenticated} userlist={userlist} residence="living5"/>
                </div>
            </section>

            <section id="coworking" className="home-section wg-featured " style={{paddingTop : "17px", paddingBottom : "15px"}}>
                <div className="container">
                    <House
                        title="코워킹"
                        subtitle="코워킹 소개글"
                        images={
                            [
                                'https://cdn.imweb.me/thumbnail/20190807/4c9f85e767801.png',
                                'https://cdn.imweb.me/thumbnail/20190807/3fa062106cb97.png',
                                'https://cdn.imweb.me/thumbnail/20191121/a72ef4d8e6549.jpg',
                                'https://cdn.imweb.me/thumbnail/20190807/0d79a6a3cb930.png',
                                'https://cdn.imweb.me/thumbnail/20191121/c57a10e1b68b9.jpg',
                                'https://cdn.imweb.me/thumbnail/20191121/a3f20aa351b7a.jpg',
                                'https://cdn.imweb.me/thumbnail/20190807/f8abd2ae4017f.png',

                            ]
                        }
                    />
                    <People authenticated={authenticated} userlist={userlist} residence="coworking"/>
                </div>
            </section>

            <section id="alumni" className="home-section wg-featured " style={{paddingTop : "17px", paddingBottom : "15px"}}>
                <div className="container">
                    <House
                        title="알럼나이"
                        subtitle="알럼나이 소개글"
                        images={
                            [
                            ]
                        }
                    />
                    <People authenticated={authenticated} userlist={userlist} residence="alumni"/>
                </div>
            </section>

        </>
    );
}

export default Home;