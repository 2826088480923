import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import Compressor from 'compressorjs';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 200,
    height: 200,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function Dropzone({uploadS3, profile}) {

    const [files, setFiles] = useState([]);

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        maxSize: 5 * 1024 * 1024,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file),
            })));
            uploader(acceptedFiles[0]);
        }
    });

    const uploader = (file) => {
        new Compressor(file, {
            quality: 0.6,
            success(compressedFile) {
                uploadS3(compressedFile, file.name);
            },
            error(err) {
                console.log(err.message);
            },
        });
    };

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    const thumbsfromS3 = (url) => (
        <div style={thumb}>
            <div style={thumbInner}>
                <img
                    src={url}
                    style={img}
                />
            </div>
        </div>
    );

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <input disabled className="form-control w-100" placeholder="이 곳을 눌러 프로필 사진을 업로드해주세요."/>
            </div>
            <aside style={thumbsContainer}>
                {profile ? (thumbsfromS3(profile.url)) :  (thumbs)}
            </aside>
        </>
    );
};

export default Dropzone;