import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const OwlCarouselImage = styled.img`
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  object-fit: cover;

  @media screen and (min-width: 769px) {
    height: 540px;
  }
  @media screen and (max-width: 768px) {
    height: 270px;
  }
`;

function House({title, subtitle, images}) {
    return (
        <>
        <div className="home-section-bg "></div>
            <div className="container">

                <div className="row  ">

                    <div className="col-12 col-lg-3 section-heading">
                        <h1>{title}</h1>
                        <p>{subtitle}</p>
                    </div>

                    <div className="col-12 col-lg-9">
                        <OwlCarousel items={1}
                                     dots={false}
                                     className="owl-theme"
                                     loop autoplay={true}
                                     margin={8}>
                        {
                            images && images.length > 0 &&
                            images.map((image, idx) => (
                                <OwlCarouselImage className="img" src={image} key={`img-${idx}`}/>
                            ))
                        }
                        </OwlCarousel>
                </div>

                </div>

            </div>
        </>
    );
}

export default withRouter(House);