import React from 'react';
import  { withRouter } from 'react-router-dom'
import Dropzone from "./Dropzone";

import axios from 'axios';
import AWS from "aws-sdk";
import { v4 as uuidv4 } from 'uuid';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: '',
            name: '',
            role: '',
            residence: 'where',
            introduce: '',
            sns: ''
        };

        AWS.config.region = 'ap-northeast-2';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'ap-northeast-2:1869b657-f2b2-4474-9f6f-2addfb877bfb',
        });

        this.s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            params: {Bucket: 'wannaknow'}
        });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    uploadS3 = (compressedFile, name) => {
        this.s3.upload({
            Key: uuidv4() + name,
            Body: compressedFile,
            ACL: 'public-read'
        }, (err, data) => {
            if (err) {
                return console.log('There was an error uploading your photo: ', err.message);
            }
            console.log('Successfully uploaded photo. ', data.Location);
            this.setState({ 'url': data.Location });
        });
    };

    gohome = () => {
        window.location.replace('/');
        this.props.attachJS([{ src: "/js/rebatchCards.js" }]);
    }

    handleSubmit = (event) => {
        let endpoint = process.env.REACT_APP_SERVER_URL + (this.props.profile ? '/api/edit' : '/api/write');

        event.preventDefault();
        if(this.validateForm()){
            let data = {
                userid: this.props.account.id,
                url: this.state.url,
                name: this.state.name,
                role: this.state.role,
                introduce: this.state.introduce,
                residence: this.state.residence,
                sns: this.state.sns
            }
            if (this.props.profile)
                data.recordid = this.props.profile.recordid;

            axios
                .post(endpoint, data, {withCredentials: true})
                .then((res) => {
                    console.log(res.data);
                    if (res.data.message === "create") {
                        alert('가입 완료! 논파의 승인을 기다려주세요 :) \n 승인 후에는 소개글을 수정할 수 있습니다.');
                        this.props.clearAuth();
                        this.gohome();
                    }else if(res.data.message === "edit"){
                        alert('수정이 완료되었습니다!');
                        this.gohome();
                    }else{
                        console.log(res.data);
                    }
                })
                .catch((err) => {
                    alert('논파에게 문의해주세요!');
                })
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    validateForm = () => {
        let validated = true;

        if(this.props.profile)
            this.setState({ 'url': this.state.url });
        else if (!this.state.url || this.state.url === '') {
            alert('프로필 사진을 등록해주세요.');
            validated = false;
        }

        if(this.props.profile)
            this.setState({ 'name': this.state.name });
        else if (!this.state.name || this.state.name === '') {
            alert('이름을 입력해주세요.');
            validated = false;
        }

        if(this.props.profile)
            this.setState({ 'residence': this.state.residence });
        else if (!this.state.residence || this.state.residence === 'where') {
            alert('거주지를 등록해주세요.');
            validated = false;
        }

        if(this.props.profile)
            this.setState({ 'introduce': this.state.introduce });
        else if (!this.state.introduce || this.state.introduce === '') {
            alert('긴 소개글을 작성해주세요.');
            validated = false;
        }

        if(this.props.profile)
            this.setState({ 'role': this.state.role });
        else if (!this.state.role || this.state.introduce === '') {
            alert('짧은 소개글을 작성해주세요.');
            validated = false;
        }

        return validated;
    };


    render () {
        return (
            <section id="contact" className="home-section wg-contact">
                <div className="home-section-bg"></div>
                <div className="container">
                    <div className="row">
                        {
                            this.props.account ? (
                                <>

                                <div className="col-12 col-lg-3 section-heading">
                                    <h1>wannaknow</h1>
                                    <p>{this.props.account.nickname}</p>
                                </div>

                                <div className="col-12 col-lg-9">
                                    <div className="mb-3">
                                        <form name="contact" onSubmit={this.handleSubmit}>

                                            <Dropzone uploadS3={this.uploadS3} profile={this.state.url !== "" ? this.state :this.props.profile} />

                                            <div className="form-group form-inline">
                                                <label className="sr-only" htmlFor="inputName">이름</label>
                                                <input type="text" name="name" className="form-control w-100" id="inputName"
                                                       defaultValue={this.props.profile ? this.props.profile.name : this.state.name}
                                                       disabled={this.props.profile ? true : false}
                                                       onChange={this.handleChange}
                                                       placeholder="이름 (ex, 김논스)" required />
                                            </div>

                                            <div className="form-group form-inline">
                                                <label className="sr-only" htmlFor="inputRole">역할</label>
                                                <input type="text" name="role" className="form-control w-100" id="inputRole"
                                                       defaultValue={this.props.profile ? this.props.profile.role : this.state.role}
                                                       onChange={this.handleChange}
                                                       placeholder="자신을 짧게 소개해주세요 (ex, 꿈 많은 소프트웨어 개발자)" required />
                                            </div>

                                            <div className="form-group">
                                                <label className="sr-only" htmlFor="inputResidence">호점</label>
                                                <select className="form-control w-100" id="inputResidence"
                                                        defaultValue={this.props.profile ? this.props.profile.residence : this.state.residence}
                                                        onChange={this.handleChange}
                                                        name="residence">
                                                    <option value="where" disabled>어디에 사시나요?</option>
                                                    <option value="living2">2호점</option>
                                                    <option value="living3">3호점</option>
                                                    <option value="living4">4호점</option>
                                                    <option value="living5">5호점</option>
                                                    <option value="coworking">코워킹</option>
                                                    <option value="alumni">알럼나이</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label className="sr-only" htmlFor="inputIntroduce">소개</label>
                                                <textarea name="introduce" className="form-control" id="inputIntroduce" rows="5"
                                                          defaultValue={this.props.profile ? this.props.profile.introduce : this.state.introduce}
                                                          onChange={this.handleChange}
                                                          placeholder={this.props.account.nickname + "님은 어떤 분이신가요?"} required >
                                                </textarea>
                                            </div>

                                            <div className="form-group form-inline">
                                                <label className="sr-only" htmlFor="inputSNS">SNS</label>
                                                <input type="url" name="sns" className="form-control w-100" id="inputSNS"
                                                       defaultValue={this.props.profile ? this.props.profile.sns : this.state.sns}
                                                       onChange={this.handleChange}
                                                       placeholder="SNS 링크를 적어주세요 (ex, https://www.fb.com/nonce.community)" />
                                            </div>

                                            <button type="submit" className="btn btn-outline-primary px-3 py-2">
                                                { this.props.profile ? '수정하기' : '작성하기' }
                                            </button>

                                        </form>
                                    </div>
                                </div>
                                </>

                            ):(
                                ''
                            )
                        }

                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(Profile);