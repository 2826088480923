import React from 'react';
import {Link, withRouter} from 'react-router-dom';

function LogoutButton({ logout, history }) {
  const handleClick = () => {
    logout();
    history.push('/');
  }
  return (
      <li className="nav-item">
          <a className="nav-link" href="" onClick={handleClick}>
            <i className="fas fa-sign-out-alt" aria-hidden="true"></i>
          </a>
      </li>
  );
}

export default withRouter(LogoutButton);