
import React from "react";
import {Link} from "react-router-dom";

function UserCard({authenticated, user}){
    const to = authenticated ? `/${user.id}` : '#;';

    return (
        <div className="project-card project-item isotope-item js-id-Deep-Learning">
            <Link className="card" to={to} style={{ textDecoration: "none" }}>

                <div rel="noopener" className="card-image hover-overlay">
                    <img src={user.url} className="img-responsive lazyload" />
                </div>

                <div className="card-text">
                    <h4>{user.name}</h4>

                    <div className="article-style">
                        <p>{user.role}</p>
                    </div>

                </div>
            </Link>
        </div>
    );
}

export default UserCard;