import axios from "axios";
import React from "react";
import {withRouter, useParams} from "react-router-dom";

function User(){

    let { id } = useParams();
    const [user, setUser] = React.useState(null);

    const fetchUser = () => {
        if(id) {
            axios
                .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${id}`,
                    {withCredentials: true})
                .then(function (res) {
                    if (res.data.user) {
                        setUser(res.data.user);
                    }
                });
        }
    };

    React.useEffect(() => {
        fetchUser();
    }, []);

    return (<>{
        user ? (
            <section id="about" className="home-section wg-about">
                <div className="home-section-bg "></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div id="profile">

                                <img className="avatar avatar-circle" src={user.url} alt={user.name} />

                                    <div className="portrait-title">
                                        <h2>{user.name}</h2>
                                        <h3>{user.role}</h3>
                                    </div>

                                    <ul className="network-icon" aria-hidden="true">
                                        <li>
                                            <a href={user.sns} target="_blank" rel="noopener">
                                                <i className="fas fa-file-word big-icon"></i>
                                            </a>
                                        </li>
                                    </ul>

                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <p>{user.introduce}</p>
                        </div>
                    </div>
                </div>
            </section>
        ) : ('')
    }</>);
}

export default withRouter(User);