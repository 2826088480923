import axios from 'axios';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import './App.css';

import Home from './Home';
import User from './User';
import Header from "./Header";
import Profile from './Profile';
import AuthRoute from './AuthRoute';
import {setSessionCookie, getSessionCookie} from "./cookies";

function App() {

    const [account, setAccount] = React.useState(null); // account for mattermost
    const [profile, setProfile] = React.useState(null);

    const [checkedLogin, setCheckedLogin] = React.useState(null);
    const [isRedirected, setIsRedirected] = React.useState(false);

    const attachJS = (scripts) => {
        //Append the script element on each iteration
        scripts.map(item => {
            const script = document.createElement("script")
            script.src = item.src
            script.type = "module"
            script.async = true
            document.body.appendChild(script)
        })
    };

    const clearAuth = (account) => {
        if (account)
            setAccount(null);
        setCheckedLogin(false);
        setSessionCookie({'redirect': false});
        setIsRedirected(false);
    };

    const checkProfile = (account) => {
        console.log('checkProfile :', account.id);
        axios
            .post(`${process.env.REACT_APP_SERVER_URL}/api/me`,{
                userid: account.id,
            }, {withCredentials: true})
            .then(function(res) {
                if (res.data.me){
                    setProfile(res.data.me);
                }else{
                    if(!getSessionCookie().redirect){
                        setSessionCookie({'redirect': true});
                        setIsRedirected(true);
                        alert('프로필이 작성되지 않았거나, 승인대기중입니다!');
                    }
                }
            }).catch(function (e) {
            });
    };

    const checkLogin = () => {
        console.log('checkLogin', `${process.env.REACT_APP_SERVER_URL}/api/mattermost`);
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/api/mattermost`
                , {withCredentials: true})
            .then(function(res) {
                if (res.data.account){
                    setAccount(res.data.account);
                    checkProfile(res.data.account);
                    setCheckedLogin(true);
                }else{
                    logout();
                }
            });
    };

    const logout = () => {
        if(account) {
            axios
                .post(`${process.env.REACT_APP_SERVER_URL}/api/logout`,
                    {
                        'userid': account.id
                    }, {withCredentials: true})
                .then(function (res) {
                    clearAuth(account);
                })
        }
        clearAuth(null);
    };

    React.useEffect(() => {
        attachJS([
            { src: "/js/wowchemy-init.js" },
            { src: "/js/wowchemy.min.ffabb05383f1b16d83e7181a605e644c.js" },
        ]);
        setCheckedLogin(null);
        checkLogin();
    }, []);

    return (
        <div className="App">
            <Router>
                <Header title="wannaknow" authenticated={(account !== null)} logout={logout} checkedLogin={checkedLogin} />

                <Switch>
                    <Route exact path="/">
                        <Home authenticated={(profile !== null)} isRedirected={isRedirected} attachJS={attachJS}/>
                    </Route>
                    <Route path="/profile">
                        <Profile account={account} profile={profile} clearAuth={clearAuth} attachJS={attachJS} />
                    </Route>
                    <Route path="/:id">
                        <User />
                    </Route>

                    {/*<AuthRoute*/}
                    {/*    authenticated={(account !== null)}*/}
                    {/*    path="/profile"*/}
                    {/*    render={props => <Profile account={account} profile={profile} clearAuth={clearAuth} attachJS={attachJS} {...props} />}*/}
                    {/*/>*/}
                    {/*<AuthRoute*/}
                    {/*    authenticated={(account !== null)}*/}
                    {/*    path="/:id"*/}
                    {/*    render={props => <User {...props}  />}*/}
                    {/*/>*/}
                </Switch>
            </Router>
        </div>
    );
}

export default App;
