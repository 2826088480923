import UserCard from "./UserCard";
import React from "react";

function People({authenticated, userlist, residence}) {
    return (
        <div style={{ 'marginTop': '20px' }} className="row  justify-content-center">

            <div className="col-12 ">
                <div className="isotope projects-container js-layout-masonry ">
                    {
                        userlist &&  userlist.length > 0 &&
                        userlist
                            .filter((user) => user && user.residence === residence)
                            .map((user, idx) => (
                                <UserCard authenticated={authenticated} user={user} key={`user${idx}`}/>
                            ))
                    }

                </div>
            </div>
        </div>
    )
}

export default People;