import React from 'react';
import { Link } from 'react-router-dom';

import LogoutButton from './LogoutButton';

function Header({ title, authenticated, logout, checkedLogin }) {
    return (
        <>
        <div className="page-header">
            <nav className="navbar navbar-expand-lg navbar-light compensate-for-scrollbar" id="navbar-main">
                <div className="container">

                    <div className="d-none d-lg-inline-flex">
                        <a className="navbar-brand" href="/">{title}</a>
                    </div>

                    <button type="button" className="navbar-toggler" data-toggle="collapse"
                            data-target="#navbar-content" aria-controls="navbar" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span><i className="fas fa-bars"></i></span>
                    </button>

                    <div className="navbar-brand-mobile-wrapper d-inline-flex d-lg-none">
                        <a className="navbar-brand" href="/">{title}</a>
                    </div>

                    <div className="navbar-collapse main-menu-item collapse justify-content-start" id="navbar-content">

                        <ul className="navbar-nav d-md-inline-flex">

                            <li className="nav-item">
                                <a className="nav-link " href="/#living2"
                                   data-target="#living2"><span>2호점</span></a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link " href="/#living3"
                                   data-target="#living3"><span>3호점</span></a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link " href="/#living4"
                                   data-target="#living4"><span>4호점</span></a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link " href="/#living5"
                                   data-target="#living5"><span>5호점</span></a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link " href="/#coworking"
                                   data-target="#coworking"><span>코워킹</span></a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link " href="/#alumni"
                                   data-target="#alumni"><span>알럼나이</span></a>
                            </li>


                        </ul>
                    </div>

                    <ul className="nav-icons navbar-nav flex-row ml-auto d-flex pl-md-2">

                        {checkedLogin === null ? ('') : (authenticated ? (
                            <>
                                <LogoutButton logout={logout} />

                                <li className="nav-item">
                                    <Link to="/profile">
                                        <i className="fas fa-user" aria-hidden="true"></i>
                                    </Link>
                                </li>

                                <li className="nav-item dropdown theme-dropdown">
                                    <a href="#" className="nav-link" data-toggle="dropdown" aria-haspopup="true"
                                       aria-label="Display preferences">
                                        <i className="fas fa-moon" aria-hidden="true"></i>
                                    </a>
                                    <div className="dropdown-menu">
                                        <a href="#" className="dropdown-item js-set-theme-light">
                                            <span>Light</span>
                                        </a>
                                        <a href="#" className="dropdown-item js-set-theme-dark">
                                            <span>Dark</span>
                                        </a>
                                        <a href="#" className="dropdown-item js-set-theme-auto">
                                            <span>Automatic</span>
                                        </a>
                                    </div>
                                </li>

                            </>
                            ) : (
                                <>
                                    <li className="nav-item">
                                        <a className="nav-link" href={process.env.REACT_APP_SERVER_URL + "/api/login"}>
                                            <i className="fas fa-sign-in-alt" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown theme-dropdown">
                                        <a href="#" className="nav-link" data-toggle="dropdown" aria-haspopup="true"
                                           aria-label="Display preferences">
                                            <i className="fas fa-moon" aria-hidden="true"></i>
                                        </a>
                                        <div className="dropdown-menu">
                                            <a href="#" className="dropdown-item js-set-theme-light">
                                                <span>Light</span>
                                            </a>
                                            <a href="#" className="dropdown-item js-set-theme-dark">
                                                <span>Dark</span>
                                            </a>
                                            <a href="#" className="dropdown-item js-set-theme-auto">
                                                <span>Automatic</span>
                                            </a>
                                        </div>
                                    </li>
                                </>
                            )
                        )}
                    </ul>
                </div>
            </nav>
        </div>
        </>
    );
}

export default Header;